<template>
  <div class="warnign_bridge" v-if="total > 0 && show">
    <div class="top">
      <div class="title">消息({{alive+1}}/{{total}})
        <span class="mine" v-on:click="unShow">今日不再提醒</span>
        <span class="icon_span" v-on:click="close">
          <i class="el-icon-close"></i>
        </span>
      </div>
      <div class="msg">
        <div class="cell" v-for="one in cellList" v-bind:key="one.label">
          <div class="label">{{one.label}}</div>
          <div class="value">{{one.value}}</div>
        </div>
      </div>
      <div class="button_box" v-if="total > 1">
        <div class="pre"><el-button type="text" :disabled="alive==0" v-on:click="pre">上一条</el-button></div>
        <div class="next"><el-button type="text" :disabled="alive+1==total" v-on:click="next">下一条</el-button></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show:true,
      alive: 0,
      dataList:[],
      currentData:null,
      cellList:[],
      interval:null
    }
  },
  activated() {
    this.getWarningBridge();
    clearInterval(this.interval)
    this.interval = setInterval(this.getWarningBridge,10000)
  },
  computed:{
    total:function(){
      return this.dataList ? this.dataList.length : 0
    }
  },
  methods: {
    getWarningBridge() {
      let url = '/base/weighbridge/warningBridge'
      this.$http.post(url).then((res) => {
        let data = res.detail
        if (data) {
          if (this.dataList.length) {
            data.forEach(one=>{
              let o = this.dataList.find(item=>item.wbId==one.wbId)
              if (!o) {
                if (!this.show) {
                  this.show = true
                  this.alive = this.dataList.length
                }
                this.dataList.push(one)
              }else{
                one.weight = o.weight
              }
            })
          }else{
            this.dataList = data
          }
        }
        this.setCurrentData()
        this.setCellList()
      })
    },
    setCurrentData(){
      if (this.total==0) {
        return
      }
      this.currentData = this.dataList[this.alive]
    },
    setCellList(){
      if (this.total==0) {
        return
      }
      let weighBridge = this.currentData.weighBridge
      let weightWarning = this.currentData.weightWarning
      let list = [{
        label:'地磅名称',
        value: weighBridge.cName
      },{
        label:'地磅称重',
        value: `${weightWarning.weight}kg (预警值:${weighBridge.warningW}kg)`
      },{
        label:'地磅位置',
        value: weighBridge.addr
      },{
        label:'预警时间',
        value: weightWarning.updateT
      }]
      this.cellList = list
    },
    unShow(){
      let url = '/base/weighbridge/warningBridge/unShowToday'
      this.$http.post(url,this.currentData.weightWarning.wbId).then(()=>{
        this.dataList.splice(this.alive,1)
        this.pre()
        this.setCurrentData()
        this.setCellList()
      })
    },
    close(){
      this.show = false
    },
    next(){
      if (this.alive<this.total-1) {
        this.alive++
      }
    },
    pre(){
      if(this.alive>0){
        this.alive--
      }
    }
  }
}
</script>
<style scoped="warnign_bridge" lang="scss">
.warnign_bridge {
  position: fixed;
  z-index: 6000;
  bottom: 50px;
  right: 100px;
  width: 340px;
  box-shadow:0 0 5px 1px $bordercolor;

  .top {
    .title {
      font-size: 16px;
      padding: 20px;
      background-color: #efefef;

      .mine {
        color: $buttonbackgroud;
        margin-left: 10px;
        font-size: 14px;
        @extend .hand;
      }
    }
    .icon_span{
      font-size: 20px;
      float: right;
      @extend .hand;
    }
  }

  .msg {
    padding: 5px 20px 20px;
    background-color: #fff;

    .cell {
      display: flex;
      padding: 15px 0 0;
      font-size: 15px;

      .label {
        width: 80px;
        color: #565656;
      }

      .value {
        flex: 1;
        color: #232323;
      }
    }
  }

  .button_box{
    display: flex;
    text-align: center;
    background-color: #fff;
    >*{
      flex: 1;
      padding:0 0 10px;
      span{
        @extend .hand;
      }
    }
    .pre{
    }
    .next{
    }
  }
}
</style>