<template>
    <div id="app_entry">
        <jy-container :showLeft="true" canHide v-model="isHide">
            <template slot="left">
                <jy-menu :menu="menu"></jy-menu>
            </template>
            <template>
                <keep-alive v-if="$store.state.info ? true : false">
                    <router-view />
                </keep-alive>
            </template>
        </jy-container>
        <warning-bridge></warning-bridge>
    </div>
</template>
<script type="text/javascript">
    import warningBridge from '@/components/pages/admin/global/warningBridge'
    import { mapMutations } from "vuex";
    export default {
        data() {
            return {
                isHide: process.env.NODE_ENV !== "production"
            };
        },
        components:{
            warningBridge
        },
        activated() {
        },
        computed: {
            menu() {
                let menuList = this.$store.state.menuList;
                return menuList;
            }
        },
        watch: {
            isHide: {
                handler: function (b) {
                    this.setMenuShow(!b);
                },
                immediate: true
            }
        },
        methods: {
            ...mapMutations(["setMenuShow"]),
        }
    };
</script>
